import { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Button } from "react-bootstrap";
import { useLazyGetClientQuery } from "../../libs/redux/slices/autocomxSlice/autocomxApiSlice";
import Loadingx from "../Loadingx/Loadingx";
import * as XLSX from "xlsx";
const Metadatx = ({ opMetada, setOpMetada, metadaSelc, setMetadaSelc, metadaMaqSelc, metadaMaqExcel, setMetadaMaqExcel }) => {
  console.log(metadaMaqExcel);
  console.log(metadaMaqSelc);
  const [findClients, { isLoading }] = useLazyGetClientQuery();
  const [medadesc, setMetadesc] = useState(metadaSelc);
  const [finalMet, setFinalMet] = useState("");
  useEffect(() => {
    if (Object.keys(metadaMaqSelc).length > 0) {
      const loadMetadesc = async () => {
        let aMetadat = Object.keys(metadaMaqSelc).map(el => {
          return el;
        })
        for (let i = 0; i <= aMetadat.length; i++) {
          switch (aMetadat[i]) {
            case "CLIIDXXX":
              await findClients('&filters[CLIIDXXX]=' + metadaMaqSelc["CLIIDXXX"])
                .then((response) => {
                  if (response?.data?.data?.data) {
                    let clinom = response.data.data.data.map((client) => {
                      return client.CLINOMXX;
                    });
                    setMetadesc({ ...medadesc, "CLIENTE NOMBRE": clinom[0] })
                  }
                })
                .catch(() => {
                });
              break;
            default:
              break;
          }
        }
      }
      loadMetadesc();
    }
    // eslint-disable-next-line
  }, [])
  //useeffect para organizar metadatos
  useEffect(() => {
    if (medadesc !== "") {
      let organizar = Object.keys(medadesc).sort();
      let newObject = {};
      organizar.forEach(row => newObject[row] = medadesc[row])
      setFinalMet(newObject)
    }
  }, [medadesc])

  // Función para generar y descargar el Excel
  const downloadExcel = (docevalx, metdesxx) => {
    const data = docevalx.map(val => [val]);
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "metdesxx");
    XLSX.writeFile(wb, `${metdesxx}.xlsx`);
  };

  return (
    <>
      <Modal size="lg" show={opMetada} onHide={() => {
        setOpMetada(false)
        setMetadaSelc("")
      }}>
        <Modal.Header style={{ "backgroundColor": "#000000", "color": "white" }}>
          METADATOS
          <Button className="btn-close btn-close-white" aria-label="Close"
            onClick={() => {
              setOpMetada(false)
              setMetadaSelc("")
            }} />
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Loadingx
              width={50}
              height={50}
            />
          ) : (
            <Container>
              {
                Object.keys(finalMet).map(item => {
                  let typeMet = "";
                  typeof finalMet[item] === "object" && finalMet[item] !== null
                    ? (typeMet = finalMet[item].join(", "))
                    : (typeMet = finalMet[item]);
                  return (
                    <Row key={`itm_${item}`}>
                      <Col className="border-bottom border-dark d-grid justify-content-center">
                        {item}:
                      </Col>
                      <Col className="border-bottom border-dark d-grid justify-content-center">
                        {typeMet}
                      </Col>
                    </Row>
                  )
                })
              }
              {/* metadatos excel */}
              {metadaMaqExcel && metadaMaqExcel.length > 0 && metadaMaqExcel.map((item, index) => {
                return (
                  <Row key={`excel_row_${index}`}>
                    <Col className="border-bottom border-dark d-grid justify-content-center">
                      {item.METDESXX}:
                    </Col>
                    <Col className="border-bottom border-dark d-grid justify-content-center">
                      <button
                        onClick={() => downloadExcel(item.DOCEVALX, item.METDESXX)}
                        style={{
                          background: 'none',
                          border: 'none',
                          color: 'blue',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        Link de descarga
                      </button>
                    </Col>
                  </Row>
                );
              })}
            </Container>
          )
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Metadatx;
