import { Container } from "react-bootstrap";
import { getisLoggedIn, signIn, signOut } from "../libs/redux/slices/Loginxxx/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLogoutMutation, useLoginUserParamsMutation, useDataInfoUserMutation } from "../libs/redux/slices/Loginxxx/loginApiSlice";
import Loadingx from "../components/Loadingx/Loadingx";
import Navbarxx from "../components/Navbarxx";
import Trkgfile from "../components/Trkgfile";
import {
  getFiltersTkr,
  setFilterSeries, setFiltersTkr,
  getReset,
  setFiltsNav,
  setNavBar,
  setNumDo,
  setReset,
  resetfilters,
  setReports,
  setCliidxx
} from "../libs/redux/slices/Trkgfile/trkgfileSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGetSeriesxFiltersMutation } from "../libs/redux/slices/Trkgfile/trkgfileApislice";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logOut, { isLoading }] = useLogoutMutation();
  const filters = useSelector(getFiltersTkr);
  const resetNav = useSelector(getReset);
  const params = useParams();
  const [getseriaxFilters] = useGetSeriesxFiltersMutation();
  const [loginExternal, { isLoading: loadExt }] = useLoginUserParamsMutation();
  const isLoggedIn = useSelector(getisLoggedIn);
  const [datainfoUSer] = useDataInfoUserMutation();
  const closeSesion = async () => {
    await logOut();
    dispatch(signOut());
    dispatch(setReset(true));
    dispatch(resetfilters());
    dispatch(setReports(false));
    dispatch(setNavBar(true));
    dispatch(setNumDo(""));
    dispatch(setCliidxx(""));
  }
  useEffect(() => {
    if (params.id) {
      let userExt = false;
      let splitfilts = [];
      const splpathWindow = window.location.pathname.split("/");
      if (splpathWindow[2] !== undefined) {
        splitfilts = splpathWindow[2].split(":");
      }
      let finalFilts = "";
      splitfilts.forEach((row) => {
        let filts = "filters";
        let decodeURl = decodeURIComponent(row);
        const jsonmet = decodeURl.split("->");

        if (jsonmet.length > 1) {
          let keyBase = jsonmet[0];
          let subKey = jsonmet[1];
          let operator = null;
          if (subKey.includes("<<")) {
            operator = "<<";
          } else if (subKey.includes(">>")) {
            operator = ">>";
          } else if (subKey.includes("<")) {
            operator = "<";
          } else if (subKey.includes(">")) {
            operator = ">";
          }

          if (operator) {
            const [field, value] = subKey.split(operator);
            filts += `[${keyBase}->${field}][${operator}]=${value}`;
          } else {
            const value = subKey.split("=");
            if (value.length === 1) {
              filts += `[${keyBase}][->][${value[0]}]`;
            } else if (value.length === 2) {
              filts += `[${keyBase}][->][${value[0]}]=${value[1]}`;
            }
          }
          finalFilts += `${filts}&`;
        } else {
          const campIN = decodeURl.split("[IN]");
          if (campIN.length > 1) {
            finalFilts += `filters[${campIN[0]}][IN]=${campIN[1]}&`;
          } else {
            const operators = ["<<", ">>", "<", ">"];
            let matchedOperator = null;
            for (const op of operators) {
              if (decodeURl.includes(op)) {
                matchedOperator = op;
                break;
              }
            }

            if (matchedOperator) {
              const [key, value] = decodeURl.split(matchedOperator);
              finalFilts += `filters[${key}][${matchedOperator}]=${value}&`;
            } else {
              const camp = decodeURl.split("=");
              if (camp.length === 2) {
                finalFilts += `filters[${camp[0]}]=${camp[1]}&`;
              }
            }
          }
        }
      });
      const sendSerie = async (numeroDo, finalFilts) => {
        try {
          await getseriaxFilters({
            filters: `${finalFilts}filters[REGESTXX]=ACTIVO`
          }).unwrap().then((response) => {
            let totalData = "";
            if (numeroDo !== "") {
              totalData = response.data.concat({ "SERIEIDX": 0, "SERIEDES": "GENERAL" }, { "SERIEIDX": 1, "SERIEDES": "DOCUMENTOS CLIENTE" });
            } else {
              totalData = response.data.concat({ "SERIEIDX": 0, "SERIEDES": "GENERAL" });
            }
            totalData.sort((key, val) => key.SERIEIDX - val.SERIEIDX);
            dispatch(setFilterSeries(totalData));
          });
        } catch (error) {
          navigate(`/home`);
        }
      }
      const loginExtr = async (user) => {
        try {
          await loginExternal({ user: user })
            .unwrap().then(userData => {
              if (userData?.data?.[0].INFOUSER) {
                userExt = true;
                const dataUser = userData?.data?.[0];
                dispatch(signIn({ user, dataUser }));
              }
            })
        } catch (error) {
          navigate(`/home`);
        }
      }
      if (params.user && !isLoggedIn) {
        userExt = true;
        loginExtr(params.user);
      }
      if (!userExt && finalFilts !== "") {
        let numeroDo = "";
        splitfilts.forEach(row => {
          const decoUrl = decodeURIComponent(row);
          let aRows = decoUrl.split("=");
          if (aRows[0] === "JSONMETX->DOCNROXX") {
            numeroDo = aRows[1]
          }
        })
        dispatch(setNumDo(numeroDo))
        dispatch(setNavBar(false));
        console.log(finalFilts);
        dispatch(setFiltersTkr(`${finalFilts}filters[REGESTXX]=ACTIVO`));
        dispatch(setFiltsNav(`${finalFilts}filters[REGESTXX]=ACTIVO`));
        sendSerie(numeroDo, finalFilts);
        if (!isLoggedIn) {
          const validateTkn = async () => {
            datainfoUSer().then((userData) => {
              if (userData?.data?.data?.[0].INFOUSER.USRIDXXX) {
                const dataUser = userData.data.data?.[0];
                const userName = userData.data.data?.[0].INFOUSER.USRIDXXX;
                dispatch(signIn({ userName, dataUser }));
                navigate("/home")
              }
            }).catch((e) => { console.log('jkhjkhkjh') })
          }
          validateTkn();
        }
      }
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  return (isLoading || loadExt) ? (
    <Loadingx />
  ) : (
    <>
      <Container fluid id="navs-home">
        <Navbarxx
          key={resetNav}
          closeSesion={closeSesion} />
      </Container>
      <Container fluid id="div-components">
        {
          filters ? (
            <Trkgfile
              defaultFilters={""} />
          ) : (
            <Loadingx />
          )
        }

      </Container>
    </>
  )
}

export default HomePage;
