import { apiSlice } from "../apiSlice";

export const repoprogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientesDocsCompletos: builder.query({
      query: () => '/getClientes'
    }),
    sendMail: builder.mutation({
      query: (data) => ({
        url: '/sendMails',
        method: 'POST',
        body: data,
      }),
    }),
  })
});

export const {
  useGetClientesDocsCompletosQuery,
  useSendMailMutation,
} = repoprogApiSlice;
