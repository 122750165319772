import React from "react";
import Form from "react-bootstrap/Form";
import { Button, Col, Row } from "react-bootstrap";
import * as XLSX from 'xlsx';
import Autocomx from "../Autocomx";

const Metadata = ({ metadat, setJsonmetx, consult = false, defaultValue, extraFilter = "", isDisabled, jsonmetx, metaExcel }) => {
  console.log(jsonmetx);

  let cObject = "";
  let objectMetadata = { ...metadat };
  let value = defaultValue ? defaultValue : "";
  let valueDefault = value;
  if (
    objectMetadata.METTIPXX === "LISTASELECCIONFUNCION" ||
    objectMetadata.METTIPXX === "LISTASELECCIONFUNCIONMULTIPLE"
  ) {
    let valueDef = [];
    if (typeof value === "object") {
      value.forEach((element) => {
        valueDef = [...valueDef, { id: element, label: element }];
      });
    } else {
      valueDef = value !== "" ? [...valueDef, { id: value, label: value }] : [];
    }
    valueDefault = valueDef;
  }

  // Función para manejar la carga de archivos Excel
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryString = reader.result;
        const wb = XLSX.read(binaryString, { type: "binary" });
        const sheet = wb.Sheets[wb.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const columnData = data.map(row => row[0]).filter(val => val !== undefined && val !== "");
        const key = objectMetadata.METIDXXX;
        setJsonmetx(key, columnData);
      };
      reader.readAsBinaryString(file);
    } else {
      alert("Por favor, selecciona un archivo Excel (.xls, .xlsx)");
    }
  };

  /**
   * Funcion que se encarga de descargar la planilla con los metadatos
   */
  const downloadExcel = () => {
    const metadata = metaExcel.find(item => item.METIDXXX === objectMetadata.METIDXXX);
    if (metadata) {
      if (metadata.DOCEVALX && metadata.DOCEVALX.length > 0) {
        const ws = XLSX.utils.aoa_to_sheet([]);
        const data = metadata.DOCEVALX.map(val => [val]);
        XLSX.utils.sheet_add_aoa(ws, data, { origin: "A1" });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "objectMetadata.METDESXX");
        XLSX.writeFile(wb, `${objectMetadata.METDESXX}.xlsx`);
      } else {
        alert("No hay datos disponibles en DOCEVALX para este metadato.");
      }
    } else {
      alert(`El metadato ${objectMetadata.METIDXXX} no se encuentra en metaExcel.`);
    }
  };



  // Lógica para renderizar los diferentes tipos de metadatos
  switch (objectMetadata.METTIPXX) {
    case "BOOLEANO":
      cObject = (
        <Form.Check
          type="checkbox"
          onChange={(e) => {
            const key = objectMetadata.METIDXXX;
            setJsonmetx(key, e.target.value);
          }}
        ></Form.Check>
      );
      break;
    case "NUMERO":
    case "TEXTO":
      let type = objectMetadata.METTIPXX === "NUMERO" ? "number" : "text";
      cObject = (
        <>
          <Form.Control
            type={type}
            onChange={(e) => {
              const key = objectMetadata.METIDXXX;
              setJsonmetx(key, e.target.value);
            }}
            defaultValue={valueDefault}
          ></Form.Control>
        </>
      );
      break;
    case "FECHA":
      cObject = (
        <>
          <Form.Control
            type="date"
            name={objectMetadata.METIDXXX}
            onChange={(e) => {
              const key = objectMetadata.METIDXXX;
              setJsonmetx(key, e.target.value);
              e.target.value = e.target.value.split("/").reverse().join("-");
            }}
            defaultValue={valueDefault}
          ></Form.Control>
        </>
      );
      break;
    case "LISTASELECCION":
      cObject = (
        <>
          <Form.Select
            onChange={(e) => {
              const key = objectMetadata.METIDXXX;
              setJsonmetx(key, e.target.value);
            }}
            defaultValue={valueDefault}
          >
            <option value={""}>[SELECCIONE]</option>
            {objectMetadata.METOPXXX.split(",").map((option, key) => {
              return (
                <option value={option} key={`option-${metadat.METIDXXX}-${key}`}>
                  {option}
                </option>
              );
            })}
          </Form.Select>
        </>
      );
      break;
    case "LISTASELECCIONFUNCION":
      cObject = (
        <>
          <Autocomx
            objectMetadata={objectMetadata}
            setJsonmetx={setJsonmetx}
            defaultValue={valueDefault}
            extraFilter={extraFilter}
            isDisabled={isDisabled}
            jsonmetx={jsonmetx}
          />
        </>
      );
      break;
    case "LISTASELECCIONFUNCIONMULTIPLE":
      cObject = (
        <>
          <Autocomx
            objectMetadata={objectMetadata}
            setJsonmetx={setJsonmetx}
            isMultiple={!consult}
            defaultValue={valueDefault}
            extraFilter={extraFilter}
            jsonmetx={jsonmetx}
          />
        </>
      );
      break;
    case "EXCEL":
      cObject = (
        <>
          <Row className="align-items-center">
            <Col md="11" className="mb-2">
              <Form.Control
                type="file"
                accept=".xls,.xlsx"
                onChange={handleFileChange}
                className="mr-2"
              />
            </Col>
            <Col md="1" className="mb-2">
              <div className="tooltip-container">
                <Button
                  variant="white"
                  onClick={downloadExcel}
                  className="d-flex align-items-center"
                >
                  <i className="bi bi-file-earmark-arrow-down" style={{ fontSize: '1.5rem' }}></i>
                </Button>
                <div className="tooltip-text">
                  Descargar Planilla
                </div>
              </div>
            </Col>
          </Row>
        </>
      );
      if (consult) {
        cObject = (
          <>
            <Autocomx
              objectMetadata={objectMetadata}
              setJsonmetx={setJsonmetx}
              defaultValue={valueDefault}
              extraFilter={extraFilter}
              isDisabled={isDisabled}
              jsonmetx={jsonmetx}
            />
          </>
        );
      }
      break;
    default:
      cObject = "";
      break;
  }

  return cObject;
};

export default Metadata;
