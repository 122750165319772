import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { getpermUser } from "../../libs/redux/slices/Loginxxx/loginSlice";
import Metadata from "../Metadata/Metadata";

const Modatipx = ({ openTipoDocModal, setOpenTipoDocModal, tipoDocs, selectedTipoDoc, setSelectedTipoDoc, jsonMeta, setJsonMeta, filtrarDocs }) => {
  const permUser = useSelector(getpermUser);
  const [metaDatos, setMetadatos] = useState([]);

  const closeModal = () => {
    setOpenTipoDocModal(false);
  };

  const metadatos = useCallback((nTipDoc) => {
    const datos = tipoDocs.find((tipDoc) => tipDoc.TIPOIDXX === Number(nTipDoc));
    if (!datos) return;
    const oEmpre = permUser.find((item) => item.EMPNITXX === datos.EMPNITXX);
    const oAreas = oEmpre.GEST0001.find((item) => item.AREAIDXX === datos.AREAIDXX);
    const oSerie = oAreas.GEST0002.find((item) => item.SERIEIDX === datos.SERIEIDX);
    const oTipos = oSerie.GEST0003.find((item) => item.TIPOIDXX === datos.TIPOIDXX);
    const oMetaD = oTipos.GEST0004.filter((item) => item.METIDXXX !== "CLIIDXXX" && item.METIDXXX !== "DOCNROXX");
    setMetadatos(oMetaD);
  }, [permUser, tipoDocs]);

  const updateJsonmetx = useCallback((key, value) => {
    setJsonMeta((prevJsonMeta) => ({
      ...prevJsonMeta,
      [key]: value,
    }));
  }, [setJsonMeta]);

  const limpiarFilt = () => {
    setSelectedTipoDoc("");
    setMetadatos([]);
  }

  useEffect(() => {
    if (selectedTipoDoc) {
      metadatos(selectedTipoDoc);
    }
  }, [selectedTipoDoc, metadatos]);

  return (
    <Modal size="lg" show={openTipoDocModal} onHide={closeModal} >
      <Modal.Header style={{ "backgroundColor": "#000000", "color": "white" }}>
        Filtro por Tipo Documental
        <Button className="btn-close btn-close-white" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col sm={5} md={5} lg={5}>
              Tipo Documental
            </Col>
            <Col sm={7} md={7} lg={7}>
              <Form.Select
                value={selectedTipoDoc}
                onChange={(e) => setSelectedTipoDoc(e.target.value)}
              >
                <option value="">SELECCIONE</option>
                {tipoDocs.map((item, key) => (
                  <option key={key} value={item.TIPOIDXX}>{item.TIPODESX}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          {metaDatos.length > 0 && metaDatos.map((data, index) => (
            <Row key={index}>
              <Col className="mb-2">
                {data.METDESXX}:
                <Metadata metadat={data} setJsonmetx={updateJsonmetx} defaultValue={jsonMeta[data.METIDXXX]} consult={true} jsonmetx={jsonMeta} />
              </Col>
            </Row>
          ))}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button lg={1} className="btn-yellow m-2" onClick={filtrarDocs}>Filtrar</Button>
        <Button lg={1} className="btn-yellow m-2" onClick={limpiarFilt}>Limpiar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Modatipx;
