import { Button, Container, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import {
  useGetDownloadFilesMutation,
  useGetdownloadForZipMutation,
  useGetDeleteFileTmpMutation,
  useLazyGetDownloadZipQuoleQuery,
  useLazyGetDownloadPdfQuoleQuery
} from "../../libs/redux/slices/Trkgfile/trkgfileApislice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getinfoUser } from "../../libs/redux/slices/Loginxxx/loginSlice";

const Modadesc = ({ openModal, setOpenModal, checSelc, dataTkr }) => {
  const [getDownload, { isLoading }] = useGetDownloadFilesMutation();
  const [getDeleteFileTmp] = useGetDeleteFileTmpMutation();
  const [getDownForZip, { isLoading: isLoadZip }] = useGetdownloadForZipMutation();
  const [getDownForZipQuole, { isLoading: isLoadZipQuole }] = useLazyGetDownloadZipQuoleQuery();
  const [getDownForPdfQuole, { isLoading: isLoadPdfQuole }] = useLazyGetDownloadPdfQuoleQuery();
  const [parActual, setParActual] = useState(0);
  const [avance, setAvance] = useState(0);
  const [allPdf, setAllPdf] = useState(true);
  const infoUser = useSelector(getinfoUser);
  const setDonwUnido = async (e) => {
    if (e) {
      let sumPag = 0;
      if (checSelc.length > 1) {
        checSelc.forEach(idArch => {
          const document = dataTkr.find(item => item.IDARCHXX === idArch);
          sumPag += document.NUMPAGXX;
        });
      }
      if (sumPag <= 40) {
        setParActual(1)
        let pdfResultPar = "";
        const { div: araySend, parts: numPart } = partitionSelect(2);
        for (let ind = 0; ind <= numPart - 1; ind++) {
          let list = araySend[ind].map((item) => item).join(',');
          try {
            const response = await getDownload({
              "filters[IDARCHXX][IN]": list,
              totalPartes: numPart,
              parteActual: ind + 1,
              pdfResult: pdfResultPar,
            });

            const porcent = ((ind + 1) * 100) / numPart;
            setParActual(porcent);
            let avanc = avance + 2;
            setAvance(avanc);
            if (numPart !== (ind + 1)) {
              pdfResultPar = response.data.data.path;
            } else {
              await downloadAndSaveFile(response.data.data.path, response.data.data.name);
              setOpenModal(false);
            }
          } catch (error) {
            console.error("Ocurrio un error al generar la descarga:", error);
            alert("Ocurrio un error al descargar los Documentos seleccionados, por favor intente nuevamamente.");
            setOpenModal(false);
            return;
          }
        }
      } else {
        let list = checSelc.map((item) => item).join(',');
        try {
          const response = await getDownForPdfQuole({
            "filters[IDARCHXX][IN]": list
          }).unwrap();
          const porcent = 100;
          setParActual(porcent);
          if (response.data) {
            alert("Se realizará el envio del link para su descarga al correo " + infoUser.USREMAXX);
            setOpenModal(false);
          }
        } catch (error) {
          console.error("Error al descargar zip:", error);
          alert("Ocurrio un error al descargar los Documentos seleccionados, por favor intente nuevamamente.");
          setOpenModal(false);
          return;
        }
      }
    }
  }

  const downloadAndSaveFile = async (filePath, fileName) => {
    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.addEventListener('click', async () => {
        setTimeout(async () => {
          URL.revokeObjectURL(link.href);
          link.remove();
          await getDeleteFileTmp(fileName);
        }, 3000);
      });
      link.click();
    } catch (error) {
      console.error("Error al descargar el archivo:", error);

    }
  };

  const setDonwZip = async (e) => {
    if (e) {
      let list = checSelc.map((item) => item).join(',');
      let sumPag = 0;
      if (checSelc.length > 1) {
        checSelc.forEach(idArch => {
          const document = dataTkr.find(item => item.IDARCHXX === idArch);
          sumPag += document.NUMPAGXX;
        });
      }
      if (sumPag >= 40) {
        try {
          const response = await getDownForZipQuole({
            "filters[IDARCHXX][IN]": list,
            totalPartes: 1,
            parteActual: 1
          }).unwrap();
          const porcent = 100;
          setParActual(porcent);
          if (response.data) {
            alert("Se realizará el envio del link para su descarga al correo " + infoUser.USREMAXX);
            setOpenModal(false);
          }
        } catch (error) {
          console.error("Error al descargar zip:", error);
          alert("Ocurrio un error al descargar los Documentos seleccionados, por favor intente nuevamamente.");
          setOpenModal(false);
          return;
        }
      } else {
        try {
          const response = await getDownForZip({
            "filters[IDARCHXX][IN]": list,
            totalPartes: 1,
            parteActual: 1
          }).unwrap();
          const porcent = 100;
          setParActual(porcent);
          const opened = openFileInNewTab(response.data.path);
          if (opened) {
            await delay(3000);
            await deleteFileTmp(response.data.name);
            setOpenModal(false);
          }
        } catch (error) {
          console.error("Error al descargar zip:", error);
          alert("Ocurrio un error al descargar los Documentos seleccionados, por favor intente nuevamamente.");
          setOpenModal(false);
          return;
        }
      }
    }
  }

  const openFileInNewTab = (filePath) => {
    return window.open(filePath, "_blank");
  };

  const deleteFileTmp = async (fileName) => {
    try {
      await getDeleteFileTmp(fileName);
    } catch (error) {
      console.error("Error al eliminar archivo temporal zip:", error);
    }
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const partitionSelect = (numFile) => {
    let araySend = {};
    let numPart = 1;
    let abjReturn = [];
    if (checSelc.length > numFile) {
      numPart = Math.ceil(checSelc.length / numFile);
      let contador = 0;
      for (let index = 0; index <= (numPart - 1); index++) {
        if (index === (numPart - 1)) {
          araySend[index] = checSelc.slice(contador, checSelc.length)
        } else {
          araySend[index] = checSelc.slice(contador, (contador + numFile))
          contador = contador + numFile;
        }
      }
    } else if (checSelc.length > 0) {
      araySend[0] = checSelc.slice(0, checSelc.length)
    }
    abjReturn["div"] = araySend;
    abjReturn["parts"] = numPart;
    return abjReturn;
  }

  const closeModal = () => {
    if (!isLoadZip && !isLoading && !isLoadPdfQuole && !isLoadZipQuole) {
      setOpenModal(false)
    }
  }

  //hook encargado de verificar si los documentos seleccionatos son PDF para habilitar la opcion de undios
  useEffect(() => {
    if (checSelc.length > 1) {
      checSelc.forEach(idArch => {
        const document = dataTkr.find(item => item.IDARCHXX === idArch);
        if (document.TYPEXXXX !== 'application/pdf') {
          setAllPdf(false);
          return;
        }
      });
    }
    // eslint-disable-next-line
  }, [checSelc, dataTkr])

  return (
    <>
      <Modal show={openModal} onHide={() => closeModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          Como Quiere que se realice la Descarga:
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Form.Check
                value={1}
                label={"Cada Documento por Separado"}
                onChange={(e) => setDonwZip(e.target.checked)}
                disabled={isLoadZip || isLoading || isLoadPdfQuole}
              />
            </Row>
            <Row>
              <Form.Check
                value={2}
                label={"Todos los Documentos Unidos en un Pdf"}
                onChange={(e) => setDonwUnido(e.target.checked)}
                disabled={isLoadZip || isLoading || !allPdf || isLoadPdfQuole}
              />
            </Row>
          </Container>
          <Row>
            <ProgressBar striped variant="warning" now={`${parActual}`} label={`${avance}/${checSelc.length}`} />
          </Row>

        </Modal.Body>
        <Modal.Footer>
          {
            isLoading || isLoadZip || isLoadZipQuole || isLoadPdfQuole ? (
              <Button type="button" className="mt-4 mb-4 btn-login">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Descargando...
              </Button>
            ) : ""
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Modadesc;
